import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import License from '../views/License.vue';
import Support from '../views/Support.vue';
import About from '../views/About.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import ConsentData from '../views/ConsentData.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/license',
    name: 'License',
    component: License,
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/consent-data',
    name: 'ConsentData',
    component: ConsentData,
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
