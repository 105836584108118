<template>
  <main-layout>
    <div class="container my-5">
      <div class="h1 text-center main__headline_color_blue main_bold">
        СОГЛАСИЕ НА ОБРАБОТКУ И ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
      </div><br>

      <p>
        Оставляя свои данные на Сайте Мониторинг качества ds.codes путем заполнения полей онлайн-заявок (регистраций, форм обратной связи, запросов и т.д.), я в соответствии с Федеральным законом РФ от 27.07.2006 № 152-ФЗ «О персональных данных» (далее - Закон 152-ФЗ) настоящим выражаю свое согласие на обработку ООО «Дилайт Софт» (ИНН 7713589840, ОГРН1067746749124, местонахождения 127422 г. Москва, ул. Тимирязевская, дом 1 стр. 3, ком. 3517-3518) моих персональных данных (далее – Данные) в нижеуказанных целях и способами сроком на 5 (Пять) лет.
      </p><br>

      <p>
        <span class="main_bold">Перечень действий с Данными</span>, на совершение которых дается согласие:
        - обработка (включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, уничтожение персональных данных), передача Данных третьим лицам, в случаях, установленных действующим законодательством.
      </p><br>

      <p>
        Общее описание вышеуказанных способов обработки Данных приведено в Законе 152-ФЗ.
      </p><br>

      <p>
        <span class="main_bold">Перечень Данных</span>, на обработку которых дается согласие:
        фамилия, имя, отчество, номер телефона, адрес электронной почты, место работы, должность.
      </p><br>

      <p>
        <span class="main_bold">Цель обработки Данных</span> получение ответов, комментариев на мои вопросы, направление на указанный мной адрес электронной почты и/или номер телефона информации о товарах, работах, услугах ООО «Дилайт Софт».
      </p><br>

      <p>
        Подтверждаю, что Данные и иные сведения, относящиеся ко мне предоставлены мной ООО «Дилайт Софт» добровольно, являются достоверными, принадлежат мне лично.
      </p><br>

      <p>
        <span class="main_bold">Отзыв Согласия осуществляется:</span> путем направления уведомления с требованием о прекращении обработки Данных на электронный адрес <a href="mailto:info@ds.codes">info@ds.codes</a>
      </p><br>

      <p>
        Согласие автоматически отзывается в случае истечения срока, на которое выдано настоящее согласие.
      </p><br>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/Main.vue';

export default {
  name: 'ConsentData',
  components: {
    MainLayout,
  },
};
</script>
