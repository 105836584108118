<template>
  <main-layout>
    <div class="container pt-md-5 mb-5">
      <div class="license__headline license__headline_color_dark-blue text-center">
       Система мониторинга качества «ЦИФЛАБ»
      </div>
      <div class="row mt-md-5">
        <div class="col-md-6 order-2 order-md-1">
          <div class="main__headline main__headline_color_blue">
            Kак отследить <span class="main__headline_color_green">качество продукции</span><br>в условиях растущей номенклатуры?
          </div>
          <div class="main__subtitle main__subtitle_color_purple">
            Система мониторинга «ЦИФЛАБ» решит эти задачи:
          </div>
          <ul class="nav flex-column ms-4 mt-5">
            <li class="text-start text-wrap d-flex align-items-center">
              <img
                :src="Collaboration"
                alt=""
              >
              <div class="ms-4">
                Создайте карточку заказа
              </div>
            </li>
            <li class="text-start text-wrap d-flex align-items-center mt-2">
              <img
                :src="FolderInvoices"
                alt=""
              >
              <div class="ms-4">
                Создайте карточку заказчика и производителя
              </div>
            </li>
            <li class="text-start text-wrap d-flex align-items-center mt-2">
              <img
                :src="Invite"
                alt=""
              >
              <div class="ms-4">
                Внесите сведения о приемке продукции и сертификате
              </div>
            </li>
            <li class="text-start text-wrap d-flex align-items-center mt-2">
              <img
                :src="Inspection"
                alt=""
              >
              <div class="ms-4">
                Отследите отгрузку
              </div>
            </li>
            <li class="text-start text-wrap d-flex align-items-center mt-2">
              <img
                :src="GroupTask"
                alt=""
              >
              <div class="ms-4">
                Направьте отчет экономисту
              </div>
            </li>
            <li class="text-start text-wrap d-flex align-items-center mt-2">
              <img
                :src="Planner"
                alt=""
              >
              <div class="ms-4">
                Сформируйте аналитический отчет
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-6 text-center order-1 order-md-2 mt-4 mt-md-0">
          <img
            class="img-fluid"
            :src="Processing"
            alt=""
          >
        </div>
      </div>
      <div class="row main__section">
        <div class="col-md-6 text-center mt-md-5">
          <img
            class="img-fluid d-none d-md-inline-block"
            :src="MarketingTop"
            alt=""
          >
          <img
            class="img-fluid"
            :src="MarketingBottom"
            alt=""
          >
        </div>
        <div class="col-md-6 mt-md-0">
          <div class="main__subtitle main_uppercase main_bold main__headline_color_blue">
            <span class="main__headline_color_red">Система «Цифлаб»</span> автоматизирует учет выпускаемой продукции в условиях постоянно растущей <span class="main__headline_color_green">номенклатуры</span>
          </div>
          <ul class="nav flex-column">
            <li class="d-flex align-items-center mt-4">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Каждая партия продукции индивидуализирована и прослеживаема;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Пользователь может максимально четко определить номенклатурные характеристики по каждому виду продукции;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Система сохраняет данные по всей техническо-экономической документации, созданной на продукцию;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Оператор отслеживает время прохождения продукции по каждой стадии;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Гибкая система учета данных по отгрузке;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Автоматическое формирование отчетов для экономиста;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Интеграция с другими базами данных, позволяющих автоматизировать работу пользователя;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Аналитический модуль для руководителя;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-3">
                Архив данных обеспечит длительное хранение записей
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row main__section">
        <div class="col-md-6 order-2 order-md-1">
          <div class="main__subtitle main_uppercase main_bold main__headline_color_blue">
            Единое <span class="main__headline_color_magenta">рабочее пространство</span> для компании, осуществляющей мониторинг качества продукции и <span class="main__headline_color_yellow-green">производственных процессов</span>
          </div>
          <ul class="nav flex-column">
            <li class="d-flex align-items-center mt-4">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-2">
                Автоматическая загрузка и выгрузка данных в системе;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-2">
                Возможность ручного добавления и изменения данных в системе;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-2">
                Данные хранятся на сервере, что позволяет объединить данные с разных филиалов предприятий в одном месте без использования дополнительных программ или приложений;
              </span>
            </li>
            <li class="d-flex align-items-center mt-2">
              <span class="fs-6 fw-normal py-2">
                <Check/>
              </span>
              <span class="ms-2">
                Простой интерфейс, позволяющий работать пользователю без особых навыков.
              </span>
            </li>
          </ul>
        </div>
        <div class="col-md-6 text-center order-1 order-md-2 mt-md-0">
          <img
            class="img-fluid"
            :src="Brainstorming"
            alt=""
          >
        </div>
      </div>
      <div class="row main__section">
        <div class="col-md-6 text-center mt-md-0">
          <img
            class="img-fluid"
            :src="Development"
            alt=""
          >
        </div>
        <div class="col-md-6">
          <div class="main__subtitle main_uppercase main_bold main__headline_color_blue">
            Российская разработка
          </div>
          <ul class="nav flex-column">
            <li class="d-flex align-items-center mt-4">
              <span class="p-2 pb-1">
               <DoubleCheck/>
              </span>
              <span class="ms-3">
                Возможность доработки под конкретную задачу или интеграции с другими системами
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/Main.vue';
import Processing from '@/assets/img/processing.png';
import Collaboration from '@/assets/img/collaboration.png';
import FolderInvoices from '@/assets/img/folder-invoices.png';
import Invite from '@/assets/img/invite.png';
import Inspection from '@/assets/img/inspection.png';
import GroupTask from '@/assets/img/group-task.png';
import Planner from '@/assets/img/planner.png';
import MarketingTop from '@/assets/img/marketing-top.png';
import MarketingBottom from '@/assets/img/marketing-bottom.png';
import Brainstorming from '@/assets/img/brainstorming.png';
import Development from '@/assets/img/development.png';
import Check from '@/assets/img/check.svg';
import DoubleCheck from '@/assets/img/double-check.svg';
import Icons from 'bootstrap-icons/bootstrap-icons.svg';
// import Verification from '@/assets/img/verification.svg';
// import list from '@/assets/img/list.svg';
// import inventory from '@/assets/img/inventory.svg';
// import economist from '@/assets/img/economist.svg';
// import contact from '@/assets/img/contact.svg';
// import contactMail from '@/assets/img/contact_mail.svg';

export default {
  name: 'About',
  components: {
    MainLayout,
    Check,
    DoubleCheck,
    // Verification,
    // list,
    // inventory,
    // economist,
    // contact,
    // contactMail,
  },
  data: () => ({
    Processing,
    Collaboration,
    FolderInvoices,
    Invite,
    Inspection,
    GroupTask,
    Planner,
    MarketingTop,
    MarketingBottom,
    Brainstorming,
    Development,
    Check,
    DoubleCheck,
    Icons,

  }),
};
</script>
