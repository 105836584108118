<template>
  <main-layout>
    <Carousel class="slider" :loop="true"
    :perPage="1"
    :autoplay='true'
    :autoplayTimeout="5000"
    :paginationColor="'rgba(255, 255, 255, 0.2)'"
    :paginationActiveColor="'rgb(255, 255, 255)'">
      <Slide class="slide" :style="{'background-image': `url(${require('@/assets/img/sliderBG/slide1.png')})`}">
      <div class="container">
        <div class="subtitle">
          Разработка информационных систем<br/>
          и програмнных продуктов на основе
          </div>
        <div class="title">
           Искусственного <br/>интеллекта
        </div>
      </div>
      </Slide>
       <Slide class="slide" :style="{'background-image': `url(${require('@/assets/img/sliderBG/slide2.jpg')})`}">
      <div class="container">
        <div class="subtitle">
         Скучное и трудоемкое отдаем<br/>
          на откуп автоматам и нейросетям
          </div>
        <div class="title">
          Творчество - людям
        </div>
      </div>
      </Slide>
       <Slide class="slide" :style="{'background-image': `url(${require('@/assets/img/sliderBG/slide3.jpg')})`}">
      <div class="container">
        <div class="subtitle">
          Автоматизация<br/>
          процессов - это гарантия:
          </div>
        <div class="title">
          Эффективности,<br/>Сокращения затрат,<br>Полного контроля
        </div>
      </div>
      </Slide>
    </Carousel>
    <div class="services">
      <div class="container">
        <div class="services-title title-b align-items-center">
          Наши услуги
        </div>
        <div class="services-list d-flex justify-content-center">
          <div class="services-list-item" v-for="(item,i) in servisecList" :key="i">
            <div class="services-list-item-title title-b">{{item.title}}</div>
            <ul class="services-list-items d-flex flex-column">
              <li class="item d-flex" v-for="(li,i) in item.li" :key="i">
                <list-dot class="list-dot"/> {{li}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="trust">
      <div class="container d-flex flex-column justify-content-center my-5">
        <div class="title-b">Нам Доверяют</div>
        <div class="trust-list">
          <NGU class="trust-list-item ngu"/>
          <img class="trust-list-item nov" :src="Novo" alt="">
          <img class="trust-list-item ugz" :src="UGZ" alt="">
          <VM class="trust-list-item vm"/>
          <RT class="trust-list-item rt"/>
      </div>
    </div>
    </div>
    <div class="about">
      <div class="container my-5">
        <div class="title-b">О нас</div>
        <div class="text">
          <p>
            Компания «Дилайт Софт» создана в 2006 г.
          </p>
          <br>
          <p>
            Более 10 лет мы реализуем сложные и высоконагруженные технологические решения с помощью команды, объединяющей в себе как опытных, так и начинающих специалистов в области IT.
            Мы занимаемся разработкой и внедрением прикладных инструментов для бизнеса, управления и образования, предлагая нашим клиентам самые современные и востребованные инструменты и методологию в области искусственного интеллекта, машинного обучения, работы с базами данных.
          </p>
          <br>
          <p>
            Наша миссия - создавать продукты, улучшающие качество бизнеса и жизни, а также вовлекать в технологии будущего как можно большее количество людей и организаций.
          </p>
          <br>
          <p>
            Мы всегда открыты для молодежи и будем рады принять в свою команду студентов и выпускников технических ВУЗов страны.
            Если вы начинающий разработчик или специалист по обслуживанию программного обеспечения, просьба направить нам резюме на почту или оставить обращение на сайте.
          </p>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/Main.vue';
import ListDot from '@assets/img/list-dots.svg';
import { Carousel, Slide } from 'vue-carousel';
import NGU from '@/assets/img/trust/NSU.svg';
import Novo from '@/assets/img/trust/novo.png';
import RT from '@/assets/img/trust/rt-techpriemka.svg';
import UGZ from '@/assets/img/trust/UGZ.png';
import VM from '@/assets/img/trust/video-matrix.svg';

export default {
  name: 'Home',
  components: {
    MainLayout,
    Carousel,
    Slide,
    ListDot,
    NGU,
    VM,
    RT,
  },
  data() {
    return {
      servisecList: [
        {
          title: 'Разработка',
          li: ['CRM - систем', 'ERP - сиcтем', 'АИС - сиcтем', 'Мобильные приложения'],
        },
        {
          title: 'Интеграция',
          li: ['Установка Linux, Apache, Nginx', 'Установка различных пакетов', 'Сторонними сервисами', 'Платежными системами'],
        },
        {
          title: 'Поддержка',
          li: ['Мониторинг', 'Установка и обеспечение защиты сервера', 'Администрирование', 'Техподдержка'],
        },
      ],
      Novo,
      UGZ,
    };
  },
};
</script>

<style lang="scss">
.slider{
  margin-top: -50px;
  margin-bottom:50px ;
  .slide{
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    .container{
      padding-top: 50px;
      height: 550px;
      .subtitle{
        margin-top: 75px;
        font-size: 18px;
        text-transform: uppercase;
        color: #FFFFFF;

      }
      .title{
        font-weight: bold;
        font-size: 36px;
        text-transform: uppercase;
        color: #FFFFFF;

      }
    }
  }
  .VueCarousel-pagination{
    margin-top: -100px;
    z-index: 2;
  }
}
.services-list{
  margin-top: 40px;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  &-item{
    width: 350px;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 25px 10px 25px 20px;
    &-title{
      font-size: 24px !important;
    }
  }
  &-items{
    row-gap:15px;
    justify-content: center;
    margin-top:40px;

    .item{
      align-items: center;
    }
  }
}
.trust{
  .title-b{
    margin-bottom: 20px;
  }
  &-list{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    row-gap:26px;

    @media screen and (max-width: 1199px) {
      width: 696px;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
    &-item{
      &.ngu{
        height: 64px;
        width: 210px;
      }
      &.nov{
        width: 227px;
        height: 50px;
      }
      &.ugz{
        width: 100px;
        height: 100px;
      }
      &.vm{
        height: 59px;
        width: 181px;
      }
    }
  }
  }
.about{
  .container{
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 40px 5rem;
    .text{
      margin-top: 20px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 20px 1rem;
      width: 350px;
    }
  }
}

</style>
