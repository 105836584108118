<template>
  <div
  class="drawer-menu"
  :class="{'drawer-menu__visible':visible}">
    <div class="drawer-menu__blur"></div>
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  emits: ['toggleVisible'],
};
</script>

<style lang="scss">
.drawer-menu{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  z-index: -1;
  transition: 0.6s ease;
  &__blur{
    opacity: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgb(59, 59, 59);
  }
  &__list{
    position: fixed;
    width: 100vw;
    padding: 52px 48px 0 ;
    left: -100vw;
    height: 100vh;
    background-color: #fff;
    transition: left 0.5s ease;
  }
  &__visible{
    z-index: 30;
    .drawer-menu__blur{
      opacity: 0.3;
      transition: opacity 0.5s ease-in;
    }
    .drawer-menu__list{
      left: 0;
      transition: left 0.5s ease;
    }

  }
}
</style>
