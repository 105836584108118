<template>
<div id="_layout-main">
  <div class="header">
    <div class="container d-flex justify-content-between align-items-center">
      <div class="header-logo">
        <router-link to="/" class="logo">
          <ImgLogo class="header-logo"/>
        </router-link>
      </div>
      <nav class="header-nav">
        <ul class="header-nav-menu">
          <li class="header-nav-menu-item">
            <router-link
              class="header-nav-menu-link"
              :class="{ active: $route.path === '/' }"
              to="/"
            >
              О компании
            </router-link>
          </li>
          <li class="header-nav-menu-item">
            <router-link
              class="header-nav-menu-link"
              :class="{ active: $route.path ==='/about' }"
              to="/about"
            >
              Продукты
            </router-link>
          </li>
          <li class="header-nav-menu-item">
            <router-link
              class="header-nav-menu-link"
              :class="{ active: $route.path === '/license' }"
              to="/license"
            >
              Тарифы
            </router-link>
          </li>
          <li class="header-nav-menu-item">
            <router-link
              class="header-nav-menu-link"
              :class="{ active: $route.path === '/support' }"
              to="/support"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="topbar__item d-md-none" @click="menuVisible = true">
        <span class="material-icons-outlined"><Burger/></span>
      </div>
    </div>
  </div>
  <main class="main">
    <slot />
  </main>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="copyright col-md-6 d-flex align-items-center">
          © ООО «Дилайт Софт» 2021 г.
        </div>
        <div class="col-md-6 d-md-flex justify-content-end align-items-center">
          <a class="link-light" href="tell:+74993946371">+7 (499) 394-63-71</a>
          <a class="ms-2 link-light" href="mailto:info@ds.codes">info@ds.codes</a>
        </div>
      </div>
    </div>
  </footer>
  <v-drawer-menu :visible="menuVisible">
    <div class="drawer-menu__list">
      <div class="navigation">
        <div class="title">
          Меню
        </div>
        <div class="close_btn" @click="toggleVisible">
          <Close />
        </div>
      </div>
      <ul class='router-list'>
        <li class="router-list__item">
          <router-link to="/">
            Главная
          </router-link>
        </li>
        <li class="router-list__item">
          <router-link to="/about">
            Продукты
          </router-link>
        </li>
        <li class="router-list__item">
          <router-link to="/license">
            Тарифы
          </router-link>
        </li>
        <li class="router-list__item">
          <router-link to="/support">
            Контакты
          </router-link>
        </li>
      </ul>
    </div>
  </v-drawer-menu>
  <v-top />
  </div>
</template>

<script>
import VTop from '@/components/VTop.vue';
import VDrawerMenu from '@/components/VDrawerMenu.vue';
import ImgLogo from '@/assets/img/logo.svg';
import Location from '@/assets/img/location.png';
import Phone from '@/assets/img/phone.png';
import Mail from '@/assets/img/mail.png';
import Contacts from '@/assets/img/contacts.png';
// import Menu from "@/assets/img/menu.jpg";
import Info from '@/assets/img/info.png';
import Icons from 'bootstrap-icons/bootstrap-icons.svg';
import Burger from '@assets/img/burger.svg';
import Close from '@assets/img/close.svg';

export default {
  name: 'Main',
  components: {
    VTop,
    ImgLogo,
    Burger,
    VDrawerMenu,
    Close,
  },
  data: () => ({
    Icons,
    Contacts,
    // Menu,
    Info,
    Location,
    Mail,
    Phone,
    menuDrawerVisible: false,
    contactsDrawerVisible: false,
    menuVisible: false,
  }),
  methods: {
    toggleVisible() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>

<style lang="scss">

#_layout-main{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.copyright{
  font-size: 12px;
  color:rgb(218, 218, 218);
}
.nav{
  min-width:300px;
}
.drawer-menu__list{
  display: flex;
  flex-direction: column;
  .navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 1em;
    }
  }
  .router-list{
    width: 100%;
    flex-basis:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    &__item>a{
      color: #222;
      text-decoration: none;
      font-size: 1.5em;
    }
  }
}
</style>
