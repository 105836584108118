<template>
  <div class="popup__overlay">
    <div
      class="popup__close btn-close"
      aria-label="Close"
      @click="$emit('onPopup')"
    />
    <div class="popup__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPopup',
  emits: ['onPopup'],
};
</script>

<style lang="scss">
.popup {
  &__overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    overflow-y: auto;
  }

  &__content {
    margin: 2rem;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>
