<template>
  <transition name="fade">
    <div
      v-if="isScroll"
      class="scroll-top"
      @click="onClick"
    >
     <arrow />
    </div>
  </transition>
</template>

<script>
import arrow from '@assets/img/arrow.svg';

export default {
  name: 'VTop',
  data() {
    return {
      isScroll: false,
      scrollY: 0,
      ticking: false,
      trigerOffset: 500,
    };
  },
  components: {
    arrow,
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, false);
  },
  methods: {
    toggleVisible() {
      this.ticking = false;
      this.scrollY > this.trigerOffset
        ? this.isScroll = true
        : this.isScroll = false;
    },
    requestTick() {
      if (!this.ticking) {
        requestAnimationFrame(this.toggleVisible);
      }
      this.ticking = true;
    },
    onScroll() {
      this.scrollY = window.scrollY;
      this.requestTick();
    },
    onClick() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
.scroll-top {
  position: fixed;
  right: 2rem;
  bottom: 100px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
      display: none;
  }
}
</style>
