<template>
  <main-layout>
    <!-- <div class="container d-flex justify-content-center">
      <div class="mt-5">
        <form
          class="form"
          @submit.prevent="submitForm"
        >
          <div class="row">
            <div class="col-xl-3" />
            <div class="col-xl-6">
              <div>
                <label
                  class="d-inline-block form-label"
                  for="inputName"
                >Имя</label>
                <input
                  id="inputName"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Ваше имя"
                >
              </div>
              <div class="mt-2">
                <label
                  class="d-inline-block form-label"
                  for="inputPhone"
                >Телефон</label>
                <input
                  id="inputPhone"
                  type="tel"
                  name="phone"
                  class="form-control"
                  placeholder="Ваш телефон"
                >
              </div>
              <div class="mt-2">
                <label
                  class="d-inline-block form-label"
                  for="textareaText"
                >Информация</label>
                <textarea
                  id="textareaText"
                  class="form-control"
                  name="text"
                  rows="3"
                  placeholder="Сообщение"
                />
                <span class="form-text text-muted">Если необходимо указать дополнительную информацию</span>
              </div>
            </div>
            <div class="col-xl-3" />
          </div>
          <div class="row mt-3">
            <div class="col-xl-3" />
            <div class="col-xl-6">
              <button
                type="submit"
                class="btn btn-primary fw-bold mr-2"
              >
                Отправить
              </button>
              <div class="form-text text-muted mt-3">
                *Заполняя форму, Вы выражаете согласие на обработку персональных данных ООО "Дилайт Софт" в соответствии с законом №152-ФЗ "О персональных данных" от 27.07.2006. Компания «Дилайт Софт» гарантирует соблюдение политики безопасности и конфиденциальности, направленной на защиту от потери, неправомерного использования или несанкционированного распространения персональных данных и информации конфиденциального характера
              </div>
            </div>
            <div class="col-xl-3" />
          </div>
        </form>
      </div>
    </div> -->
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-6 d-flex justify-content-center">
          <div>
            <div class="support__headline support_color_blue">
              Мы находимся по адресу:
            </div>
            <div class="support__text mt-3">
              127422 г. Москва, ул. Тимирязевская,<br>
              дом 1 строение 3, комната 3517-3518<br>
              метро Дмитровская
            </div>
            <div class="support__headline support_color_brown mt-4">
              Режим работы:
            </div>
            <div class="support__text mt-3">
              Понедельник – пятница с 9.30 до 18.30<br>
              Прием заявок и обращений: по рабочим дням с 9.00 до 20.00
            </div>
            <div class="support__headline support_color_blue mt-4">
              Телефон:
            </div>
            <div class="support__text mt-3">
              <a class="link-w" href="tell:+74993946371">+7 (499) 394-63-71</a><br>
              <a class="link-w" href="tell:+79250241903">+7 (925) 024-19-03</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-center">
          <img
            class="img-fluid"
            :src="CustomerService"
            alt=""
          >
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 d-flex align-items-center justify-content-center order-2 order-md-1 my-5 my-md-0">
          <img
            class="img-fluid"
            :src="CustomerSupport"
            alt=""
          >
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <div class="support__headline support_uppercase support_bold">
            Оставить заявку или отзыв
          </div>
          <form
            v-if="!message"
            class="support__text_small support_uppercase"
            @submit.prevent="onSubmit"
          >
            <div class="d-flex flex-column mt-3">
              <label for="nameField">Как к Вам обращаться (Ваше ФИО)<span class="text-danger">*</span></label>
              <input
                id="nameField"
                class="support__form-field mt-2"
                type="text"
                name="name"
                required
              >
            </div>
            <div class="d-flex flex-column mt-3">
              <label for="companyField">Название организации или подразделения</label>
              <input
                id="companyField"
                class="support__form-field mt-2"
                type="text"
                name="company"
              >
            </div>
            <div class="d-flex flex-column mt-3">
              <label for="phoneField">Контактный телефон или e-mail<span class="text-danger">*</span></label>
              <input
                id="phoneField"
                class="support__form-field mt-2"
                type="text"
                name="phone"
                required
              >
            </div>
            <div class="d-flex flex-column mt-3">
              <label for="textField">Текст сообщения<span class="text-danger">*</span></label>
              <textarea
                id="textField"
                class="support__form-field mt-2"
                name="text"
                cols="30"
                rows="10"
                required
              />
            </div>
            <input
              class="support__form-button mt-4"
              type="submit"
              value="Отправить"
            >
          </form>
          <div
            v-if="!message"
            class="support__text support__text_xsmall support_uppercase support__text_justify mt-4"
          >
            Нажимая на кнопку «ОТПРАВИТЬ», <a
              class="support__link"
              href="/consent-data"
              target="_blank"
            >
              я даю согласие на обработку своих персональных данных
            </a> компанией ООО «Дилайт Софт" в соответствии с законом №152-ФЗ "О персональных данных" от 27.07.2006. и <a
              class="support__link"
              href="/privacy-policy"
              target="_blank"
            >
              соглашаюсь с политикой конфиденциальности
            </a>.
          </div>
          <div
            v-if="message"
            class="support__text support_uppercase mt-4"
          >
            {{ message }}
          </div>
        </div>
      </div>
      <div class="row my-md-5" />
    </div>
  </main-layout>
</template>

<script>
import axios from 'axios';
import MainLayout from '@/layouts/Main.vue';
import CustomerService from '@/assets/img/support/customer-service.png';
import CustomerSupport from '@/assets/img/support/customer-support.png';
import Background from '@/assets/img/support/background.png';

export default {
  name: 'Support',
  components: {
    MainLayout,
  },
  data: () => ({
    CustomerService,
    CustomerSupport,
    Background,
    message: '',
  }),
  methods: {
    async onSubmit(event) {
      const data = new FormData(event.target);
      const url = '/send.php';
      const method = 'post';
      const headers = { 'X-Requested-With': 'XMLHttpRequest' };

      try {
        const response = await axios({
          url,
          method,
          headers,
          data,
        });
        if (response.status === 200) {
          this.message = response.data;
        }
      } catch (error) {
        if (error.isAxiosError) {
          console.log(error.isAxiosError);
        } else {
          console.log(error);
        }
      }
    },
  },
};
</script>
